import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Api_Client from "../miniverse-core/src/app_components/api_client";
import {MiniverseComponent} from "../miniverse-core/web_component";

const app = createApp(App);

app.use(router);

Api_Client.getAppGlobalConfig().then( ()=> {
    router.isReady().then(() => {
        // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
        // FINALLY, mount the app
        // customElements.define('miniverse-component', MiniverseComponent);
        // console.info("Added MyElement to custom element registry!", MiniverseComponent);
        app.mount("#app")
    });
});
