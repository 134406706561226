import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('../views/LandingView.vue')
    },
    {
        path: '/EDIT/:area/:privateid?/:alias?',
        component: () => import('../views/EditorView.vue')
    },
    {
        path: '/:area/:privateid?/:alias?',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/busy',
        name: 'busy',
        component: () => import('../views/BusyView.vue')
    },
    {
        path: '/failed',
        name: 'failed',
        component: () => import('../views/NoauthView.vue')
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
