export default class Api_Client {
    // public static API_SERVER?: string;
    // private static _has_server = false;

    /*public static setServer(_api_server: string|undefined) {
        this.API_SERVER = _api_server;
        this._has_server = true;
    }*/

    /*public static getConfig(_file:string) {
        let url = null;
        if (this._has_server) {
            url = Api_Client.API_SERVER + '/getConfig/' + _file
        } else {
            url = _file
        }

        return Api_Client._api(url)
            .then((response) => {
                return JSON.parse(response)
            })
            .catch((err)=>{
                console.log(err);
                throw new Error(err.text);
            })
    }*/

    // From blob
    public static getConfig(_file:string) {
        const url = global.STORAGE_ROOT + global.STORAGE_CONTAINER + process.env.VUE_APP_STORAGE_CONFIG_ROOT + _file + '?' + Date.now();
        return Api_Client._api(url)
            .then((response) => {
                return JSON.parse(response)
            })
            .catch((err)=>{
                throw new Error(err.text);
            })
    }

    // From blob
    public static getAppGlobalConfig() {
        return fetch(process.env.BASE_URL + "config.json")
            .then((response) => response.json())
            .then((config) => {
                global.API = config.API;
                Api_Client._api(config.API + '/getConfig/')
                    .then((response) => {
                        const jresp = JSON.parse(response);
                        const data = jresp[0];
                        global.COLYSEUS = data.COLYSEUS;
                        global.LIVEKIT = data.LIVEKIT;
                        global.STORAGE_ROOT = data.STORAGE_ROOT;
                        global.STORAGE_CONTAINER = data.STORAGE_CONTAINER;
                        global.VERSION = data.VERSION;
                        global.AZURE_SAS_TOKEN = data.AZURE_SAS_TOKEN;
                        global.AZURE_ACCOUNT = data.AZURE_ACCOUNT;
                        return
                    })
                    .catch((err)=>{
                        console.error(err);
                        throw new Error(err.text);
                    })
            })
    }

    // public static checkRoom(_roomType:string, _roomId:string, _bots:number) {
    //     return Api_Client._api(global.API + '/checkRoom/' + _roomType + '/' + _roomId)
    //         .catch((err)=>{
    //             throw new Error(err.text);
    //         })
    // }

    public static checkRoom(_node_config:any) {
        return Api_Client._api_post(global.API + '/checkRoom/', _node_config)
            .catch((err)=>{
                throw new Error(err.text);
            })
    }

    public static closeLKRoom(_roomId:string|null) {
        return Api_Client._api(global.API + '/endevent/' + _roomId)
            .then((status) => {
                return status;
            }).catch(()=>{
                throw new Error('no room to close');
            })
    }

    public static authenticateUser(_body_data:any): Promise<void> {
        return Api_Client._api_post<string>(global.API  + '/authenticate', _body_data).then((res)=>{
            Api_Client.saveUserToken(res);
        }).catch((err)=> {
            throw new Error(err.text);
        })
    }

    public static checkUserAuthentication() {
        return Api_Client._api_token('checkauth', Api_Client.getUserToken(), 'get').then(()=>{
            return true;
        }).catch(()=> {
            return false;
        })
    }

    public static generateToken(_room: string, _sessionId: string): Promise<string> {
        return Api_Client._api(global.API + '/getToken/' + _sessionId + '/' + _room)
            .then((token) => {
                return token
            })
            .catch((err)=>{
                throw new Error(err.text);
            })
    }

    public static callEndpoint(_ep:string) {
        return Api_Client._api(global.API + '/' + _ep)
            .then((response) => {
                return JSON.parse(response);
            })
            .catch(()=>{
                return '';
            })
    }

    public static postEndpoint(_ep:string, _data:any) {
        return Api_Client._api_post(global.API + '/' + _ep, _data)
            .then((response) => {
                return JSON.parse(response);
            })
            .catch(()=>{
                return '';
            })
    }

    public static callURL(_url:string, _method = 'get') {
        return Api_Client._api_nocors(_url, _method)
            .then((response) => {
                return JSON.parse(response);
            })
            .catch((err) => {
                return err;
            })
    }

    public static callURLToken(_url:string, _token:string, _method:string) {
        return Api_Client._api_token(_url, _token, _method)
            .then((response) => {
                return JSON.parse(response);
            })
            .catch((err) => {
                return err;
            })
    }

    public static _api<T>(url: string, _method='get'): Promise<string> {
        return fetch(url, { method:_method})
            .then(response => {
                if (!response.ok || response.status != 200) {
                    throw new Error(response.status.toString())
                }
                return response.text() as Promise<string>
            }).catch((err) => {
                throw new Error(err.message)
            })
    }

    public static _api_nocors<T>(url: string, _method='get'): Promise<string> {
        const headers = {'mode':'no-cors'};
        return fetch(url, { method:_method, headers, mode: "no-cors"})
            .then(response => {
                if (!response.ok || response.status != 200) {
                    throw new Error(response.status.toString())
                }
                return response.text() as Promise<string>
            }).catch((err) => {
                throw new Error(err.message)
            })
    }

    private static _api_token<T>(url: string, _token: string, _method='get'): Promise<string> {
        const headers = { 'Authorization': 'Bearer ' + _token };
        return fetch(url, { method:_method, headers })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.status.toString())
                }
                return response.text()
            }).catch((err) => {
                throw new Error(err.message)
            })
    }

    private static _api_post<T>(url: string, _data:any): Promise<string> {
        const strbody = JSON.stringify(_data);
        return window.fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: strbody
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.text() as Promise<string>
            }).catch((err) => {
                throw new Error(err)
            })
    }

    public static saveUserData(_userdata:any): void {
        if (typeof _userdata == 'string') {
            sessionStorage.setItem("userData", _userdata);
        } else {
            sessionStorage.setItem("userData", JSON.stringify(_userdata));
        }
    }

    public static getUserData(): any {
        const userdata = sessionStorage.getItem("userData");
        if (userdata) {
            return JSON.parse(userdata);
        }
        return null;
    }

    public static saveUserToken(_userdata:any): void {
        if (typeof _userdata == 'string') {
            sessionStorage.setItem("userToken", _userdata);
        } else {
            sessionStorage.setItem("userToken", JSON.stringify(_userdata));
        }
    }

    public static getUserToken(): any {
        return sessionStorage.getItem("userToken");
    }

    public static getUserPrefs(): any {
        const userprefs = sessionStorage.getItem("userPrefs");
        if (userprefs) {
            return JSON.parse(userprefs);
        }
        return {};
    }

    public static saveUserPrefs(_userprefs:any): any {
        if (typeof _userprefs == 'string') {
            sessionStorage.setItem("userPrefs", _userprefs);
        } else {
            sessionStorage.setItem("userPrefs", JSON.stringify(_userprefs));
        }
    }
}
